/* eslint-disable max-len */
import { sitedata } from '@data';
import { getSEO, PageSEO } from '@seo';
import { Link } from 'gatsby';
import React from 'react';
import xw from 'twin.macro';

interface Props {
  location: {
    pathname: string;
  };
}

const { locations, siteUrl } = sitedata;
const { location1 } = locations;
const { address } = location1;

const styles = {
  blockquote: xw`relative pl-8`,
  'blockquote-bg-1': xw`bg-blue-300 dark:bg-indigo-200`,
  'blockquote-bg-2': xw`bg-gray-300 dark:bg-blue-400`,
  'blockquote-deco': xw`absolute h-full w-2 left-0`,
};

const PrivacyPolicy = ({ location }: Props) => {
  const { pathname } = location;
  const seo = getSEO(pathname);

  return (
    <>
      <PageSEO
        description={seo.description}
        featureImage="og-privacy-policy.jpg"
        pathname={pathname}
        title={seo.title}
      />
      <div className="max-w-2xl px-4 pt-16 pb-20 mx-auto text-base text-black lg:max-w-3xl xs:px-6 lg:pt-24 lg:pb-28 md:px-8 dark:text-white">
        <div className="mb-6">
          <h1 className="mb-4 text-5xl font-bold">Privacy Policy</h1>
          <p className="text-sm dark:text-gray-400">
            Last Modified: 12th September 2021
          </p>
        </div>

        <div className="leading-8">
          <p className="mb-8">
            This Privacy Policy describes how your personal information is
            collected, used, and shared when you visit{' '}
            <Link className="inline-link" to="/">
              {siteUrl}
            </Link>{' '}
            &#40;the “Site”&#41;.
          </p>

          <h2 className="mb-3 text-3xl">Personal Information We Collect</h2>
          <p className="mb-8">
            When you visit the Site, we automatically collect certain
            information about your device, including information about your web
            browser, IP address, time zone, and some of the cookies that are
            installed on your device. Additionally, as you browse the Site, we
            collect information about the individual web pages that you view,
            what websites or search terms referred you to the Site, and
            information about how you interact with the Site. We refer to this
            automatically-collected information as “Device Information.”
          </p>
          <p className="mb-4 font-bold">
            We collect Device Information using the following technologies:
          </p>
          <ul className="mb-8 ml-5 space-y-4 list-disc">
            <li>
              <span className="font-bold dark:text-red-200">“Cookies”</span> are
              data files that are placed on your device or computer and often
              include an anonymous unique identifier. For more information about
              cookies, and how to disable cookies, visit{' '}
              <a
                className="link"
                href="https://www.allaboutcookies.org/"
                rel="noopener noreferrer"
                target="_blank"
              >
                https://www.allaboutcookies.org/
              </a>
            </li>
            <li>
              <span className="font-bold dark:text-red-200">“Log files”</span>{' '}
              track actions occurring on the Site, and collect data including
              your IP address, browser type, Internet service provider,
              referring/exit pages, and date/time stamps.
            </li>
            <li>
              <span className="font-bold dark:text-red-200">
                “Web beacons,” “tags,” and “pixels”
              </span>{' '}
              are electronic files used to record information about how you
              browse the site.
            </li>
          </ul>
          <p className="mb-20">
            When we talk about{' '}
            <span className="font-bold">“Personal Information”</span> in this
            Privacy Policy, we are talking both about Device Information.
          </p>

          <h2 className="mb-3 text-3xl">
            How Do We Use Your Personal Information?
          </h2>
          <p className="mb-4">
            We use the Device Information that we collect to help us screen for
            potential risk and more generally to improve and optimize our Site.
            For example, by generating analytics about how our visitors browse
            and interact with the Site, and to assess the success of our
            marketing and advertising campaigns&#41;.
          </p>
          <p className="mb-4">
            We use Google Analytics to help us understand how our visitors use
            the Site. You can read more about how Google uses your Personal
            Information here:{' '}
            <a
              className="inline-link"
              href="https://www.google.com/intl/en/policies/privacy/"
              rel="noopener noreferrer"
              target="_blank"
            >
              https://www.google.com/intl/en/policies/privacy/
            </a>
            .
          </p>
          <p className="mb-4">
            You can also opt-out of Google Analytics here:{' '}
            <a
              className="inline-link"
              href="https://tools.google.com/dlpage/gaoptout"
            >
              https://tools.google.com/dlpage/gaoptout
            </a>
            .
          </p>
          <p className="mb-20">
            We may also share your Personal Information to comply with
            applicable laws and regulations, to respond to a subpoena, search
            warrant or other lawful request for information we receive, or to
            otherwise protect our rights.
          </p>

          <h2 className="mb-3 text-3xl">Do Not Track</h2>
          <p className="mb-20">
            Please note that we do not alter our Site’s{' '}
            <span className="font-bold dark:text-red-200">
              data collection and use practices
            </span>{' '}
            when we see a{' '}
            <span className="font-bold whitespace-nowrap">Do Not Track</span>{' '}
            signal from your browser.
          </p>

          <h2 className="mb-3 text-3xl">
            Your Rights if you are a European resident
          </h2>
          <p className="mb-4">
            You have the right to access personal information we hold about you
            and to ask that your personal information be deleted. If you would
            like to exercise this right, please contact us through the contact
            information below.
          </p>
          <p className="mb-4">
            Additionally, if you are a European resident we note that we are
            processing your information in order to fulfill contact form
            submissions.
          </p>
          <p className="mb-20">
            Additionally, please note that your information will be transferred
            outside of Europe, including to the United States, Malaysia and
            Singapore.
          </p>

          <h2 className="mb-3 text-3xl">Changes</h2>
          <p className="mb-20">
            We may update this privacy policy from time to time in order to
            reflect, for example, changes to our practices or for other
            operational, legal or regulatory reasons.
          </p>

          <h2 className="mb-3 text-3xl">Contact Us</h2>
          <p className="mb-4">
            For more information about our privacy practices, if you have
            questions, or if you would like to make a complaint, please contact
            us by:
          </p>
          <ul className="ml-5 space-y-4 list-disc">
            <li>
              Email:{' '}
              <a className="inline-link" href={`mailto:${location1.emails[0]}`}>
                {location1.emails[0]}
              </a>
            </li>
            <li>
              <Link className="inline-link" to="/contact/">
                Contact Form
              </Link>
            </li>
            <li>
              <p className="mb-2">By mail using the details provided below:</p>
              <blockquote css={[styles.blockquote]}>
                <div
                  aria-hidden
                  css={[styles['blockquote-deco'], styles['blockquote-bg-1']]}
                />
                {address.streetAddress1},
                <br />
                {address.streetAddress2},
                <br />
                {address.postalCode} {address.addressLocality},
                <br />
                {address.addressRegion},
                <br />
                {address.country}
              </blockquote>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
